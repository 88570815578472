// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-flashcards-general-general-questions-yaml-qid-jsx": () => import("./../../../src/pages/flashcards/general/{generalQuestionsYaml.qid}.jsx" /* webpackChunkName: "component---src-pages-flashcards-general-general-questions-yaml-qid-jsx" */),
  "component---src-pages-flashcards-general-jsx": () => import("./../../../src/pages/flashcards/general.jsx" /* webpackChunkName: "component---src-pages-flashcards-general-jsx" */),
  "component---src-pages-flashcards-jsx": () => import("./../../../src/pages/flashcards.jsx" /* webpackChunkName: "component---src-pages-flashcards-jsx" */),
  "component---src-pages-flashcards-regional-jsx": () => import("./../../../src/pages/flashcards/regional.jsx" /* webpackChunkName: "component---src-pages-flashcards-regional-jsx" */),
  "component---src-pages-flashcards-regional-regional-questions-yaml-metadata-region-regional-questions-yaml-qid-jsx": () => import("./../../../src/pages/flashcards/regional/{regionalQuestionsYaml.metadata__region}/{regionalQuestionsYaml.qid}.jsx" /* webpackChunkName: "component---src-pages-flashcards-regional-regional-questions-yaml-metadata-region-regional-questions-yaml-qid-jsx" */),
  "component---src-pages-flashcards-regional-regions-yaml-region-jsx": () => import("./../../../src/pages/flashcards/regional/{regionsYaml.region}.jsx" /* webpackChunkName: "component---src-pages-flashcards-regional-regions-yaml-region-jsx" */),
  "component---src-pages-home-jsx": () => import("./../../../src/pages/home.jsx" /* webpackChunkName: "component---src-pages-home-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-policies-cookie-policy-jsx": () => import("./../../../src/pages/policies/CookiePolicy.jsx" /* webpackChunkName: "component---src-pages-policies-cookie-policy-jsx" */),
  "component---src-pages-policies-disclaimer-jsx": () => import("./../../../src/pages/policies/Disclaimer.jsx" /* webpackChunkName: "component---src-pages-policies-disclaimer-jsx" */),
  "component---src-pages-policies-impressum-jsx": () => import("./../../../src/pages/policies/Impressum.jsx" /* webpackChunkName: "component---src-pages-policies-impressum-jsx" */),
  "component---src-pages-policies-privacy-policy-jsx": () => import("./../../../src/pages/policies/PrivacyPolicy.jsx" /* webpackChunkName: "component---src-pages-policies-privacy-policy-jsx" */),
  "component---src-pages-policies-privacy-settings-jsx": () => import("./../../../src/pages/policies/PrivacySettings.jsx" /* webpackChunkName: "component---src-pages-policies-privacy-settings-jsx" */),
  "component---src-pages-test-jsx": () => import("./../../../src/pages/test.jsx" /* webpackChunkName: "component---src-pages-test-jsx" */),
  "component---src-pages-test-regions-yaml-region-jsx": () => import("./../../../src/pages/test/{regionsYaml.region}.jsx" /* webpackChunkName: "component---src-pages-test-regions-yaml-region-jsx" */)
}

